import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { bannerList, imgNotava, maleAvatar } from "../../../assets/img";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import moment from "moment/moment";
import { toast } from "react-toastify";
import {
  useDriverAcceptMutation,
  useLazyDriverRequestViewQuery,
} from "../../../constants/redux/api/api";
import axios from "axios";
import { MAPS_API_KEY } from "../../../constants/redux/api/constants";

const DriverDocument = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  // console.log("id", Id);

  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [driverDocViewApi] = useLazyDriverRequestViewQuery();
  const [driverAcceptApi] = useDriverAcceptMutation();

  const [list, setList] = useState([]);

  const [detail, setDetails] = useState([]);
  const [aadh, setAadh] = useState();
  const [lic, setLic] = useState();
  const [vehic, setVechic] = useState();
  const [rcNo, setRc] = useState();
  const [aadhars, setAadhars] = useState();
  const [insuran, setInsuran] = useState();
  const [licNo, setLicNo] = useState();
  const [modelimgs, setModelImg] = useState(false);
  const [images, setImages] = useState("");
  const [latLong, setLatLong] = useState();
  const [fitness, setFitness] = useState();
  const [panNo, setPanNo] = useState();
  const [roadTax, setRoadTax] = useState();
  const [vehicImages, setVehicImages] = useState([]);

  const [userData, setUserData] = useState();
  const [licenData, setLicenData] = useState();
  const [VehicleData, seVehicleData] = useState();
  const [aadhaarAdd, setAadhaarAdd] = useState();
  const [licenAdd, setLicenAdd] = useState();
  const [reason, setReason] = useState("");
  const [reasonErr, setReasonErr] = useState(false);

  const viewDocumnet = () => {
    driverDocViewApi(Id)
      .unwrap()
      .then((res) => {
        setDetails(res?.driver);
        setList(res?.driver);
        setReason(res?.driver?.reject_reason);
        let vehicNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 1);
        let rcNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 2);
        let insNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 3);
        let license = res?.driver?.driver_proofs?.filter((i) => i?.type == 4);
        let aadhaar = res?.driver?.driver_proofs?.filter((i) => i?.type == 5);
        let PanNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 6);
        let RoadTax = res?.driver?.driver_proofs?.filter((i) => i?.type == 7);
        let FitnessNum = res?.driver?.driver_proofs?.filter(
          (i) => i?.type == 8
        );
        let Front = res?.driver?.driver_proofs?.filter((i) => i?.type == 9);
        let Back = res?.driver?.driver_proofs?.filter((i) => i?.type == 10);
        let Left = res?.driver?.driver_proofs?.filter((i) => i?.type == 11);
        let Right = res?.driver?.driver_proofs?.filter((i) => i?.type == 12);

        if (PanNum) {
          setPanNo(PanNum[0]);
        }
        if (RoadTax) {
          setRoadTax(PanNum[0]);
        }
        if (FitnessNum) {
          setFitness(PanNum[0]);
        }
        if (Front || Back || Left || Right) {
          let images = [];
          images.push(Front[0]);
          images.push(Back[0]);
          images.push(Left[0]);
          images.push(Right[0]);
          setVehicImages(images);
        }
        console.log("check", Front, Back, Left, Right, FitnessNum, aadhaar);
        if (aadhaar && aadhaar[0]?.details) {
          try {
            let aadhaars = aadhaar[0]?.number.slice(-4);
            setAadh(aadhaars);

            let temp = JSON.parse(aadhaar[0]?.details);
            setUserData(temp);

            let add1 = [
              temp?.address?.house,
              temp?.address?.street,
              temp?.address?.po,
              temp?.address?.state,
              temp?.zip,
            ];

            setAadhaarAdd(add1);
            setAadhars(aadhaar[0]);
          } catch (error) {
            console.error("Error parsing Aadhaar details:", error);
          }
        }

        if (license && license[0]?.details) {
          try {
            let licen = license[0]?.number.slice(-4);
            setLic(licen);

            let temp1 = JSON.parse(license[0].details);
            setLicenData(temp1);
            setLicNo(license[0]);
          } catch (error) {
            console.error("Error parsing License details:", error);
          }
        }
        if (vehicNum && vehicNum[0]?.details) {
          try {
            let temp2 = JSON.parse(vehicNum[0].details);
            seVehicleData(temp2);
            setVechic(vehicNum[0]);
          } catch (error) {
            console.error("Error parsing Vehicle details:", error);
          }
        }

        if (rcNum) {
          setRc(rcNum[0]);
        }

        if (insNum) {
          setInsuran(insNum[0]);
        }

        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res?.driver?.latitude},${res?.driver?.longitude}&key=${MAPS_API_KEY}`
          )
          .then((response) => {
            const address = response?.data?.results[0];

            setLatLong(address);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  useEffect(() => {
    if (Id) {
      viewDocumnet();
    }
  }, []);

  const submitHandle = (e) => {
    let val = e.target.value;
    let formData = new FormData();

    formData.append("driver_id", Id);

    if (val == 2) {
      formData.append("verification_status", val);
      // console.log("2", val);
    } else if (val == 3) {
      formData.append("verification_status", val);
      formData.append("reject_reason", reason);
      // console.log("3", val);
    }

    if (reason?.length == 0) {
      setReasonErr(true);
    } else {
      if (val == 3) {
        const confirm = window.confirm("Are you sure to Reject?");

        if (confirm) {
          setBtn(true);
          driverAcceptApi(formData)
            .unwrap()
            .then((res) => {
              if (res?.status == "success") {
                toast.success(res?.message, {
                  position: "top-right",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  autoClose: 2000,
                });
                navigate("/driver-request");
                setBtn(false);
              } else {
                setBtn(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      } else {
        setBtn(true);
        driverAcceptApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/driver-request");
            } else {
              setBtn(false);
            }
          });
      }
    }
  };

  const modelimg = (img) => {
    setImages(img);
    setModelImg(true);
  };

  return (
    <aside>
      <TopHeader type="driver-document" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3">
          <Tab eventKey="Detail-View" title="Detail View">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for="">
                      {list?.name}
                    </p>
                    <img
                      className="avatar-img"
                      src={list?.img_url || maleAvatar}
                    />
                  </div>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver Name
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value={list?.name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver ID
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver ID"
                            className="bg-transparent"
                            type="text"
                            value={list?.id ? "#00" + list?.id : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Phone Number
                          </label>
                          <input
                            id="example-if-email"
                            name="Phone_Number"
                            placeholder="Phone Number"
                            className="bg-transparent"
                            type="text"
                            value={list?.mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Emergency Contact
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="Emergency Contact"
                            className="bg-transparent"
                            type="text"
                            value="8838726354"
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={list?.driver_detail?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/*
                   <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Gender
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="Gender "
                            className="bg-transparent"
                            type="text"
                            value={
                              userData?.gender
                                ? userData?.gender.toLowerCase() == "m"
                                  ? "Male"
                                  : "Female"
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    */}
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Number (Masked - only last four digits)
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={aadh ? "*********" + aadh : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License Number (Masked - Only Last four digits)
                          </label>
                          <input
                            type="text"
                            name="license-number"
                            id="example-if-email"
                            placeholder="license number"
                            className="bg-transparent"
                            value={lic ? "************" + lic : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/*
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Date of Birth
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="DD/MM/YYYY"
                            className="bg-transparent"
                            type="text"
                            value={
                              userData?.dob
                                ? moment(userData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                       */}

                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB Matching
                          </label>
                        </div>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="inlineCheckbox1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option2"
                        />
                        <label class="form-check-label" for="inlineCheckbox2">
                          No
                        </label>
                      </div>
                    </div> */}

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="vehicle number"
                            className="bg-transparent"
                            type="text"
                            value={vehic?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC Number
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="rc number"
                            className="bg-transparent"
                            value={rcNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            UPI ID
                          </label>
                          <input
                            placeholder="UPI ID"
                            className="bg-transparent"
                            type="text"
                            value={list?.driver_detail?.upi_id}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled>
                            {list?.driver_detail?.address}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="d-flex justify-content-end col-sm-12 modal-label-input">
                          <button
                            type="button"
                            className="comn-btn bg-success-gradient px-3 py-2 me-2"
                            // onClick={() => setAddModal(true)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Notification">
                            Add Notification
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Live Location
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled>
                            {latLong?.formatted_address}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <input
                            placeholder="address"
                            type="text"
                            name="address"
                            className="bg-transparent"
                            value="street"
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="bg-transparent"
                            placeholder="city"
                            value={"chennai"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            State
                          </label>
                          <input
                            placeholder="State"
                            type="text"
                            name="state"
                            className="bg-transparent"
                            value={"TN"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Country
                          </label>
                          <input
                            placeholder="Country"
                            type="text"
                            name="country"
                            className="bg-transparent"
                            value={"INDIA"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Pin Code
                          </label>
                          <input
                            placeholder="Pin Code"
                            type="text"
                            name="pin_code"
                            className="bg-transparent"
                            value={"756454"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </div>
            </article>
          </Tab>
          <Tab eventKey="profile" title="Document">
            <article>
              <div className="p-3 d-flex flex-wrap">
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar
                          </label>
                          <div
                            onClick={() => modelimg(aadhars?.img_url)}
                            role="button">
                            <img
                              src={aadhars?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={aadhars?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB in Aadhaar
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={
                              userData?.dob
                                ? moment(userData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address In Aadhaar
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="Address"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Registered Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="9878564545"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Father Name
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="Father Name"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {aadhaarAdd?.toString()}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License
                          </label>
                          <div
                            onClick={() => modelimg(licNo?.img_url)}
                            role="button">
                            <img
                              src={licNo?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={licNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB in License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={
                              licenData?.dob
                                ? moment(licenData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOE of License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              licenData?.doe
                                ? moment(licenData?.doe).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOI of License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              licenData?.doi
                                ? moment(licenData?.doi).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address in License
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {licenData?.temporary_address}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Fitness Certificate
                          </label>
                          <div
                            onClick={() => modelimg(fitness?.img_url)}
                            role="button">
                            <img
                              src={fitness?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Fitness Number
                          </label>
                          <input
                            placeholder="Fitness number"
                            type="text"
                            name="Fitness number"
                            className="bg-transparent"
                            value={fitness?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Registration Certificate
                          </label>
                          <div
                            onClick={() => modelimg(rcNo?.img_url)}
                            role="button">
                            <img src={rcNo?.img_url || imgNotava} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC Number
                          </label>
                          <input
                            placeholder="RC number"
                            type="text"
                            name="RC number"
                            className="bg-transparent"
                            value={rcNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Insurance Image
                          </label>
                          <div
                            onClick={() => modelimg(insuran?.img_url)}
                            role="button">
                            <img
                              src={insuran?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Insurance Number
                          </label>
                          <input
                            placeholder="Insurance number"
                            type="text"
                            name="Insurance number"
                            className="bg-transparent"
                            value={insuran?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            PAN Image
                          </label>
                          <div
                            onClick={() => modelimg(panNo?.img_url)}
                            role="button">
                            <img
                              src={panNo?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            PAN Number
                          </label>
                          <input
                            placeholder="PAN number"
                            type="text"
                            name="PAN number"
                            className="bg-transparent"
                            value={panNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-5">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Road Tax
                          </label>
                          <div
                            onClick={() => modelimg(roadTax?.img_url)}
                            role="button">
                            <img
                              src={roadTax?.img_url || imgNotava}
                              width={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Road Tax Number
                          </label>
                          <input
                            placeholder="Road Tax number"
                            type="text"
                            name="Road Tax number"
                            className="bg-transparent"
                            value={roadTax?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="p-3 m-2 bg-white rounded-2 col-12">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-12 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Images
                          </label>
                          <div className="d-flex flex-wrap">
                            <div
                              className="m-1"
                              onClick={() => modelimg(vehicImages[0]?.img_url)}
                              role="button">
                              <img
                                src={vehicImages[0]?.img_url || imgNotava}
                                width={250}
                              />
                            </div>
                            <div
                              className="m-1"
                              onClick={() => modelimg(vehicImages[1]?.img_url)}
                              role="button">
                              <img
                                src={vehicImages[1]?.img_url || imgNotava}
                                width={250}
                              />
                            </div>
                            <div
                              className="m-1"
                              onClick={() => modelimg(vehicImages[2]?.img_url)}
                              role="button">
                              <img
                                src={vehicImages[2]?.img_url || imgNotava}
                                width={250}
                              />
                            </div>
                            <div
                              className="m-1"
                              onClick={() => modelimg(vehicImages[3]?.img_url)}
                              role="button">
                              <img
                                src={vehicImages[3]?.img_url || imgNotava}
                                width={250}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="Vehicle number"
                            type="text"
                            name="Vehicle number"
                            className="bg-transparent"
                            value={vehic?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              {/* <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle
                          </label>
                          <div onClick={() =>modelimg(vehic?.img_url)} role="button">
                            <img src={vehic?.img_url||imgNotava} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="Number "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={vehic?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC Status
                          </label>
                          <input
                            placeholder="Status "
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={VehicleData?.rc_status}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Model
                          </label>
                          <input
                            placeholder="Model "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.maker_model}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Manufacturer
                          </label>
                          <input
                            placeholder="Manufacturer "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.maker_description}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Insurance UpTo
                          </label>
                          <input
                            placeholder="Insurance "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              VehicleData?.insurance_upto
                                ? moment(VehicleData?.insurance_upto).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Owner Name
                          </label>
                          <input
                            placeholder="Owner "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.owner_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Father Name
                          </label>
                          <input
                            placeholder="Father "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.father_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Mobile Number
                          </label>
                          <input
                            placeholder="number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.mobile_number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {VehicleData?.present_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div> */}
              {/* <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC
                          </label>
                          <div>
                            <img
                              src="https://static.toiimg.com/thumb/resizemode-4,msid-92497864,imgsize-68152,width-720/92497864.jpg"
                              width={150}
                            />
                          </div>
                          <small>820198367235261</small>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div> */}
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            UPI ID & Bar Code
                          </label>
                          <div
                            onClick={() =>
                              modelimg(detail?.driver_detail?.img_url)
                            }
                            role="button">
                            <img
                              src={detail?.driver_detail?.img_url || imgNotava}
                              width={130}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="form-group col-12 col-sm-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          UPI ID
                        </label>
                        <input
                          placeholder="UPI ID"
                          type="text"
                          name="aadhaar number"
                          className="bg-transparent"
                          value={detail?.driver_detail?.upi_id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                {btn == false ? (
                  <button
                    className="comn-btn bg-danger px-3 py-2 me-2"
                    onClick={() => setBtn(true)}
                    disabled={list?.verification_status == 3 ? true : false}>
                    Reject
                  </button>
                ) : null}

                {btn == true ? (
                  <button
                    className="comn-btn bg-danger px-3 py-2 me-2"
                    value={3}
                    onClick={(e) => submitHandle(e)}>
                    Reject
                  </button>
                ) : null}

                {list?.verification_status == 3 ? null : (
                  <button
                    className="comn-btn bg-success px-3 py-2 me-2"
                    value={2}
                    onClick={(e) => submitHandle(e)}>
                    Accept
                  </button>
                )}
              </div>
            </article>
          </Tab>
        </Tabs>

        <Modal
          className="table-modal"
          show={modelimgs}
          onHide={() => setModelImg(false)}
          aria-labelledby="example-modal-sizes-title-lg">
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <img src={images || imgNotava} width={480} height={250} />
          </div>
        </Modal>
      </article>
    </aside>
  );
};

export default DriverDocument;
